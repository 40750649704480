import "./App.css";
import Header from "./components/Headers/Header";
import Hero from "./components/Hero/Hero";
import Sliders from "./components/Sliders/Sliders";
import Virtual from "./components/Virtual/Virtual";
import Products from "./components/Products/Products";
import Testimonials from "./components/Testimonial/Testimonials";
import Footer from './components/Footer/Footer'
function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Sliders/>
      <Virtual/>
      <Products/>
      <Testimonials/>
      <Footer/>
        </div>
  );
}

export default App;
